<template>
	<div class='course-content'>
		<div class='course-top overflow'>
			<div class="course-title">参赛{{region_theme_text}}</div>
			<div>
				<div class="checkbox-div">
					<el-checkbox-group v-model="checkList">
						<el-checkbox label="视频" border style="margin-right: 10px;"></el-checkbox>
						<el-checkbox label="附件" border></el-checkbox>
					</el-checkbox-group>
					<!-- <i class="el-icon-refresh icon-change" @click="changeIconStatus">{{iconChange ? '且' : '或'}}</i> -->
				</div>
				<el-input :placeholder="'编码/' + util.getKeyFrontValue('name') + '/' + util.getKeyFrontValue('category') + '/所属单位/' + util.getKeyFrontValue('apply')"  class="search-input" v-model="search"></el-input>
				<button class="search-btn" type="button" @click="searchList"><i class="el-icon-search"></i>搜索</button>
				<button class="reset-btn" type="button" @click="reset">重置</button>
				<button class="back-btn" type="button" @click="edit">
					<span><img src="../assets/img/no-edit.png"></span>
					<span>返回</span>
				</button>
			</div>
		</div>
		<div>
			<div class="table-content">
				<el-table :data="tabList" v-loading="loading" border stripe :header-cell-style="{background: '#EEF7FE',color:'black'}">
					<el-table-column prop="num" label="编码" align="center" width="123"></el-table-column>
					<el-table-column :label="util.getKeyFrontValue('cover')" width="120" align="center">
						<template slot-scope="scope">
							<el-popover placement="right" title="" trigger="hover" width="250" style="cursor: pointer;">
								<el-image slot="reference" :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"
									style="height: 75px;width:60px"></el-image>
								<el-image :src="alOssUrl+scope.row.cover+util.imgSize.imgQ40"></el-image>
							</el-popover>
						</template>
					</el-table-column>
					<el-table-column prop="name" :label="util.getKeyFrontValue('name')" min-width="200">
						<template slot-scope="scope">
							<div>
								<div class="row-name">{{ scope.row.name }}</div>
								<span v-if="scope.row.relate_course_category && scope.row.relate_course_category.kind == 'labor'" class="labor-label comment-margin-right-5">劳动教育</span>
								<span v-if="scope.row.relate_course_category && scope.row.relate_course_category.kind == 'comprehensive'" class="comprehensive-label comment-margin-right-5">综合实践</span>
								<span class="stage-label">
									{{scope.row.apply_to_stages && scope.row.apply_to_stages.length>0 ? util.setStage(scope.row.apply_to_stages) : ''}}
								</span>
							</div>
						</template>
					</el-table-column>
					<!-- 课程主题 -->
					<!-- <el-table-column prop="course_category_id" :label="util.getKeyFrontValue('category')" align="center" width="120">
						<template slot-scope="scope">
							<div>
								{{scope.row.relate_course_category ? scope.row.relate_course_category.name : ''}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="affiliation" label="所属单位" align="center" width="200">
						<template slot-scope="scope">
							<div>
								{{scope.row.relate_competition_course_author ? scope.row.relate_competition_course_author.affiliation : ''}}
							</div>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="apply_to_stages" :label="util.getKeyFrontValue('apply')" align="center" width="130">
						<template slot-scope="scope">
							{{scope.row.apply_to_stages && scope.row.apply_to_stages.length>0 ? util.setStage(scope.row.apply_to_stages) : ''}}
						</template>
					</el-table-column>
					<el-table-column prop="voted_num" label="点赞数" align="center" width="100">
						<template slot-scope="scope">
							{{ scope.row.voted_num ? scope.row.voted_num : 0 }}
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="course_category_id" label="参赛组别" align="center" width="90">
						<template slot-scope="scope">
							{{util.setStage[scope.row.group]}}
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="opinion_sharing" label="课程简介" align="center">
						<template slot-scope="scope">
							<el-popover trigger="hover" placement="top" width="600" style="cursor: pointer;">
								<p>课程简介: {{ scope.row.opinion_sharing }}</p>
								<span slot="reference" class="name-wrapper">
									{{ (scope.row.opinion_sharing+'').length > 20 ? scope.row.opinion_sharing.substr(0, 20) + "..." + '' : scope.row.opinion_sharing}}
								</span>
							</el-popover>
						</template>
					</el-table-column> -->
					<!-- <el-table-column prop="course_category_id" label="状态" align="center" width="120">
						<template slot-scope="scope">
							<div v-if="scope.row.confirm_status == util.confirm_status[9].code" style="color: red;cursor: pointer;">
								<el-popover trigger="hover" placement="top">
									<p>驳回原因: {{ scope.row.reason }}</p>
									<span slot="reference" class="name-wrapper">
										<el-tag size="medium">
											<div style="color: red;">{{util.confirm_status[scope.row.confirm_status].key}}</div>
										</el-tag>
									</span>
								</el-popover>
							</div>
							<div v-else>
								{{util.confirm_status[scope.row.confirm_status].key}}
							</div>
						</template>
					</el-table-column> -->
					<el-table-column prop="relate_competition_course_comment_content.content" label="拽写评语" min-width="200">
						<template slot-scope="scope">
							<div v-if="scope.row.relate_competition_course_comment_content && scope.row.relate_competition_course_comment_content.content">
								{{ scope.row.relate_competition_course_comment_content.content }}
							</div>
							<div v-else>
								<el-button type="text" style="color: #44D89E;" v-if="scope.row.relate_own_competition_course_comment == null"
									@click="review_store(scope.row)">写评语</el-button>
								<el-button type="text" style="color: #44D89E;" v-else
									@click="review_update(scope.row)">写评语</el-button>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="course_category_id" label="评分人数" align="center" width="100">
						<template slot-scope="scope">
							{{scope.row.relate_count_competition_course_comment_belong_competition_course_expert == null ? '暂无' : scope.row.relate_count_competition_course_comment_belong_competition_course_expert.count_competition_course_expert}}
						</template>
					</el-table-column>
					<el-table-column prop="course_category_id" label="得分" align="center" width="100">
						<template slot="header" slot-scope="scope">
							得分
							<el-popover placement="top-start" width="200" trigger="hover"
								content="取所有评分的平均分">
								<i slot="reference" class="el-icon-info icon-style"></i>
							</el-popover>
						</template>
						<template slot-scope="scope">
							{{scope.row.relate_avg_competition_course_comment_belong_competition_course_expert == null ? '暂无' : scope.row.relate_avg_competition_course_comment_belong_competition_course_expert.average_rate}}
						</template>
					</el-table-column>
					<el-table-column label="操作" min-width="100" align="center" fixed="right">
						<template slot-scope="scope">
							<el-button type="text" style="color: #44D89E;" @click='detail_click(scope.row)'>详情</el-button>
							<el-button v-if="scope.row.relate_own_competition_course_comment == null" type="text" @click='review_store(scope.row)' style="color: #FF4E00;">评分</el-button>
							<el-button v-else type="text" style="color: #1FB4F9;" @click='review_update(scope.row)'>修改评分</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="block">
				<el-pagination v-if='paginationShow' background @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :page-sizes="[5, 10, 15, 20]" :page-size="pageSize"
					layout="total, sizes, prev, pager, next, jumper" :total="total" :current-page="currentPage">
				</el-pagination>
			</div>
		</div>
		<courseDetail ref="courseDetail" role="review"></courseDetail>
		<!-- 评分 -->
		<EvaluationScore ref="EvaluationScore" @refresh="getList"></EvaluationScore>
	</div>
</template>

<script>
	import courseDetail from '../components/courseDetail.vue'
	import EvaluationScore from '../components/evaluationScore.vue'
	import {
		reactive,
		ref,
		toRefs,
		watchEffect
	} from 'vue'
	import {
		useRouter
	} from 'vue-router' //引用router
	export default {
		name: 'index',
		components: {
			courseDetail,
			EvaluationScore
		},
		data() {
			return {
				region_theme_text: JSON.parse(sessionStorage.getItem('region_theme_text')) ? JSON.parse(sessionStorage.getItem('region_theme_text')) : '课程',
				tabList: [],
				pageSize: 5,
				total: 1,
				currentPage: 1,
				paginationShow: false,
				search: '',
				loading: false,
				checkList: [],
				iconChange: true,
			}
		},
		methods: {
			editLogin() {
				localStorage.clear()
				sessionStorage.clear()
				// 退出
				this.$router.push({
					name: 'login'
				})
				history.pushState(null, null, document.URL);
				window.addEventListener("popstate", function(e) {
					history.pushState(null, null, document.URL);
				}, false);
			},
			// 详情
			detail_click(row) {
				this.$refs.courseDetail.show(row)
			},
			// 修改评分
			review_update(row) {
				this.$refs.EvaluationScore.update(row)
			},
			//评分
			review_store(row) {
				this.$refs.EvaluationScore.show(row)
			},
			//返回
			edit() {
				this.$router.push({
					name: 'index'
				})
			},
			
			//分页
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				sessionStorage.setItem('currentPage', val);
				this.getList();
			},
			// 搜索
			searchList() {
				this.currentPage = 1
				this.getList()
			},
			// 重置
			reset() {
				this.search = ''
				this.checkList = []
				this.currentPage = 1
				this.getList()
			},
			getList() {
				// if (sessionStorage.getItem('review') && sessionStorage.getItem("currentPage")) {
				// 	//如果有这个就读取缓存里面的数据
				// 	this.currentPage = Number(sessionStorage.getItem("currentPage"));
				// }
				let obj = this.util.delObjItem({
					page: this.currentPage,
					limit: this.pageSize,
					sf1: this.search
				})
				let str = ''
				if(this.checkList.indexOf('视频') != -1) {
					str = '&video=1'
				}
				if(this.checkList.indexOf('附件') != -1) {
					str = str + '&file=1'
				}
				this.loading = true
				this.http.getExpertList('?region_code=' + JSON.parse(sessionStorage.getItem('region_code')) + str, obj).then((res) => {
					this.tabList = res.data.data.list
					this.loading = false
					this.total = res.data.data.total
					this.paginationShow = true
				})
			},

		},
		mounted() {
			this.getList()
		},
		destroyed() {
			sessionStorage.removeItem("review")
		},
		watch: {

		}
	}
</script>

<style scoped="scoped">
	.course-content {
		width: 68%;
		min-width: 1100px;
		height: 73vh;
		margin: 0 auto;
		background-color: white;
		padding: 20px 10px 20px 25px;
		box-sizing: border-box;
		overflow-y: scroll;
		box-shadow: 0 0 100px rgba(31, 180, 249, 0.26);
	}

	.course-top {
		/* height: 40px; */
		margin-bottom: 20px;
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
	}
	.course-top .el-checkbox-group {
		display: inline;
		margin-right: 20px;
	}

	.course-title {
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		letter-spacing: 1px;
		margin-bottom: 15px;
		flex-shrink: 0;
	}

	.el-table td,
	.el-table th {
		padding: 8px 0 !important;
	}

	.table-content {
		/* min-height: 49vh;
		max-height: 48vh; */
		min-height: 49vh;
		overflow-y: scroll;
	}

	.table-content /deep/ .el-table td {
		padding: 6px 0 !important
	}

	.table-content /deep/ .el-table th {
		padding: 6px 0 !important
	}

	/* 分页 */
	.block {
		text-align: center;
		margin-top: 20px;
	}
	/*表格hover*/
	/deep/.el-tag {
		border-radius: 0;
		background: none;
		border: none;
		color: black;
		font-size: 14px;
		/*line-height: 60px;*/
	}
	
	.search-input {
		width: 300px;
		height: 40px;
		margin-right: 20px;
	}
	.search-input /deep/ .el-input__inner {
		height: 40px;
		line-height: 38px;
	}
	.search-btn {
		width: 80px;
		height: 40px;
		background: #FF3B3D;
		box-shadow: 0px 3px 6px rgba(255, 59, 61, 0.16);
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		color: #ffffff;
		border: none;
		margin-right: 20px;
		cursor: pointer;
	}
	.search-btn:hover {
		background: #C6191F;
	}
	.search-btn i {
		margin-right: 5px;
	}
	.reset-btn {
		box-sizing: border-box;
		width: 80px;
		height: 40px;
		/* background: rgba(255, 255, 255, 0.39); */
		background: #E8E8E8;
		/* box-shadow: 0px 3px 6px #FF3B3D; */
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		/* color: #FF3B3D; */
		color: #333333;
		border: none;
		/* border: 1px solid #FF3B3D; */
		cursor: pointer;
		margin-right: 40px;
	}
	.reset-btn:hover {
		background: #D1D1D1;
	}
	.back-btn {
		box-sizing: border-box;
		/* width: 80px; */
		padding: 0 20px;
		height: 40px;
		line-height: 40px;
		background: #E8E8E8;
		/* box-shadow: 0px 3px 5px 0px rgba(255,59,61,0.1500), 0px -5px 5px 0px rgba(255,59,61,0.0500); */
		opacity: 1;
		border-radius: 5px;
		font-size: 16px;
		color: #333333;
		border: none;
		cursor: pointer;
	}
	.back-btn:hover {
		background: #D1D1D1;
	}
	.back-btn img {
		margin-right: 10px;
		width: 13px;
		height: 13px;
		margin-top: 2px;
	}
	.icon-style {
		color: #ffffff;
		background-color: #999999 !important;
		border-radius: 50%;
		margin-left: 1px;
	}
	
	.row-name {
		font-size: 14px;
		line-height: 14px;
		margin-bottom: 10px;
	}
	.labor-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #EBFFF7;
		text-align: center;
		border-radius: 1px;
		color: #44D89E;
		font-size: 10px;
		line-height: 14px;
	}
	.comprehensive-label {
		display: inline-block;
		width: 50px;
		height: 14px;
		padding: 2px 0;
		background: #FFF1EB;
		text-align: center;
		border-radius: 1px;
		color: #FB824E;
		font-size: 10px;
		line-height: 14px;
	}
	.stage-label {
		display: inline-block;
		min-width: 50px;
		height: 14px;
		padding: 2px 3px;
		background: #EFF6FF;
		text-align: center;
		border-radius: 1px;
		color: #1950A2;
		font-size: 10px;
		line-height: 14px;
	}
</style>
