<template>
	<div>
		<el-dialog :title="title" :visible.sync="visible" width="500px" center>
			<div v-loading="loading">
				<el-form :model="form" size='small' ref="form" :rules="rules">
					<el-form-item label="评分" prop="score">
						<el-input v-model.trim="form.score" placeholder="请输入评分，评分为阿拉伯数字1-10，最高得分10分"></el-input>
					</el-form-item>
					<el-form-item label="拽写评语"  prop="content">
						<el-input v-model.trim="form.content" type="textarea" show-word-limit maxlength="50" rows="4" placeholder="请输入评语"></el-input>
					</el-form-item>
				</el-form>
				<div class="btn-box">
					<button class="dialog-cancel-btn comment-margin-right-min" type="button" @click="cancel">取消</button>
					<el-button class="dialog-submit-btn" :loading="btnLoading" type="button" @click="submit">确定</el-button>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				title: '评分',
				visible: false,
				loading: false,
				form: {
					score: '',
					content: ''
				},
				rules: {
					score: [{
						required: true,
						trigger: 'change',
						validator: (rule, value, callback) => {
							//正规表达式对象
							var reg = /^\d{1,10}$/
							let flag = reg.test(value)
							if(flag) {
								if(value > 10 || value == 0) {
									callback(new Error('评分为阿拉伯数字1-10，最高得分10分'))
								} else  {
									callback()
								}
							} else {
								callback(new Error('评分为阿拉伯数字1-10，最高得分10分'))
							}
						}
					}]
				},
				listInfo: {},
				isUpdate: false,
				btnLoading: false
			}
		},
		methods: {
			show(row) {
				this.listInfo = row
				this.form.score = row.relate_own_competition_course_comment ? row.relate_own_competition_course_comment.rate.split('.')[0] : ''
				this.form.content = row.relate_competition_course_comment_content && row.relate_competition_course_comment_content.content ? row.relate_competition_course_comment_content.content : ''
				this.isUpdate = false
				this.title = '评分'
				this.btnLoading = false
				this.visible = true
			},
			update(row) {
				this.listInfo = row
				this.isUpdate = true
				this.title = '修改评分'
				this.form.score = row.relate_own_competition_course_comment ? row.relate_own_competition_course_comment.rate.split('.')[0] : ''
				this.form.content = row.relate_competition_course_comment_content && row.relate_competition_course_comment_content.content ? row.relate_competition_course_comment_content.content : ''
				this.btnLoading = false
				this.visible = true
			},
			cancel() {
				this.visible = false
			},
			submit() {
				this.$refs.form.validate((valid) => {
					if (valid) {
						this.btnLoading = true
						if (!this.isUpdate) {
							this.http.commentSure('?competition_course_id=' + this.listInfo.id, {
								rate: this.form.score,
								content: this.form.content
							}).then(res => {
								this.$message.success('已评分！');
								this.$emit('refresh');
								this.visible = false
							}).catch(err => {
								this.btnLoading = false
							})
						} else {
							this.http.commentUpdate('?id='+ this.listInfo.relate_own_competition_course_comment.id +'&competition_course_id=' + this.listInfo.id, {
								rate: this.form.score,
								content: this.form.content
							}).then(res => {
								this.$message.success('已修改评分！');
								this.$emit('refresh');
								this.visible = false
							}).catch(err => {
								this.btnLoading = false
							})
						}
					} else {
						return false;
					}
				})
			},
		},
		mounted() {
			
		},
	}
</script>

<style scoped>
	.btn-box {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
	}
	.dialog-cancel-btn {
		box-sizing: border-box;
		min-width: 60px;
		height: 33px;
		background: #D1D1D1;
		border-radius: 5px;
		text-align: center;
		padding: 9px 16px;
		color: #333333;
		border: none;
		cursor: pointer;
	}
	.dialog-cancel-btn:hover {
		background-color: #b1b1b1;
	}
	.dialog-submit-btn {
		box-sizing: border-box;
		min-width: 60px;
		height: 33px;
		background: #FF3B3D;
		border-radius: 5px;
		text-align: center;
		padding: 9px 16px;
		color: #FFFFFF;
		border: none;
		cursor: pointer;
	}
	.dialog-submit-btn:hover {
		background-color: #C6191F;
	}
</style>
